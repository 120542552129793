import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { TechnicalTab } from '../pages/TechnicalTab';
import { DownloadTab } from '../pages/DownloadTab';
import { MainTab } from '../pages/MainTab';
import { Sidebar } from '../components/sidebar/Sidebar';
import { Navbar } from '../components/navbar/Navbar';
import { PriceComponentTab } from '../pages/PriceComponentTab';
import { PricingSummary } from '../pages/PricingSummary';
import { HeatMapTab } from '../pages/HeatMapTab';
import { DataContext } from '../context/ContextProvider';
import { UserStatsTab } from '../pages/UserStatsTab';
import { AvailabilityTab } from '../pages/AvailabilityTab';
import { PilotMainTab } from '../pages/PilotMainTab';
import { MinFare } from '../pages/MinFare';


export const AppRouter = () => {
    const { adminAccess } = useContext(DataContext)

    let routes = [
        { name: "Main Tab", endpoint: "/main-tab", element: <MainTab />, onlyAdminAllow: false },
        { name: "Price Summary", endpoint: "/pricing-summary", element: <PricingSummary />, onlyAdminAllow: false },
        { name: "Price Component", endpoint: "/price-component", element: <PriceComponentTab />, onlyAdminAllow: false },
        { name: "Heat Map", endpoint: "/heat-map-tab", element: <HeatMapTab />, onlyAdminAllow: false },
        { name: "Download Tab (Do Not Use)", endpoint: "/download-tab", element: <DownloadTab />, onlyAdminAllow: false },
        { name: "Technical Tab", endpoint: "/technical-tab", element: <TechnicalTab />, onlyAdminAllow: false },
        { name: "Min Fare", endpoint: "/min-fare", element: <MinFare />, onlyAdminAllow: false },
        { name: "User Stats Tab", endpoint: "/user-stats-tab", element: <UserStatsTab />, onlyAdminAllow: true },
        { name: "Availability Tab (Do Not Use)", endpoint: "/availability-tab", element: <AvailabilityTab />, onlyAdminAllow: true },
        { name: "Main Tab (Pilot)", endpoint:  "/pilot-main-tab", element: <PilotMainTab />, onlyAdminAllow: true }
    ]


    return (
        <Router>
            <Navbar project_name={ "Project Motor" } routes={routes} />
            <Sidebar routes={routes} />
            <Routes>
                <Route path="/" element={ <Navigate to="/main-tab" /> } />
                { routes.filter((route) => adminAccess ? true : !route.onlyAdminAllow).map((route, index) => {
                    return <Route key={index} path={ route.endpoint } element={ route.element } />
                }) }
            </Routes>
        </Router>
    );
};


