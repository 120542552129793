
import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles/Page.css'
import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { FetchRenderData } from '../services/FetchRenderData'
import { Loading } from '../components/Loader/Loading'
import { LineChart2 } from '../components/visualize/LineCharts'
import { customSort } from '../utils/customSort'
import { parseData } from '../utils/parseData'
import axios from 'axios'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import { useLocation } from 'react-router-dom'


export const MinFare = () => {
    const {
        desire_position,
        isExtended,
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadMUF, setFirstReloadMUF,
        selectedFilters, setSelectedFilters,
        filters,
        handleMultiselect,
        getAccessTokenSilently,
        getSourceStatus,
        allRecordCountVsTime, setAllRecordCountVsTime,
        min_UpfrontPriceTime, setMin_UpfrontPriceTime,
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname

    const merge_data = (data1, data2) => {
        let output = []
        let res_data = {}

        if (data1.length >= 0 && "min_fare" in data1[0]) {

            for (let i = 0; i < data1.length; i++) {
                res_data[`${data1[i].local_scraped_at_timestamp}_${data1[i].source}`] = { min_fare: data1[i].min_fare, upfront_fare: null }
            }

            for (let i = 0; i < data2.length; i++) {
                if (`${data2[i].local_scraped_at_timestamp}_${data2[i].source}` in res_data) {
                    res_data[`${data2[i].local_scraped_at_timestamp}_${data2[i].source}`].upfront_fare = data2[i].upfront_fare
                } else {
                    res_data[`${data2[i].local_scraped_at_timestamp}_${data2[i].source}`] = { min_fare: null, upfront_fare: data2[i].upfront_fare }
                }
            }

            for (let key of Object.keys(res_data)) {
                output.push({ local_scraped_at_timestamp: key.split("_")[0], source: key.split("_")[1], min_fare: res_data[key].min_fare, upfront_fare: res_data[key].upfront_fare })
            }
        }
        return output
    }
    const getChartData = async (selectedFilters, useDataHook, setDataHook, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })
            // min

            const min_fare_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, useDataHook.data.fields.filter((elel) => elel !== "upfront_fare"), getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            const upfront_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, useDataHook.data.fields.filter((elel) => elel !== "min_fare"), getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)


            if (min_fare_json === null || upfront_json === null) return

            const min_sorted_data = customSort(customSort(parseData(min_fare_json), "local_scraped_at_timestamp", "ASC"), "source", "ASC")
            const upfront_sorted_data = customSort(customSort(parseData(upfront_json), "local_scraped_at_timestamp", "ASC"), "source", "ASC")
            let res_data = merge_data(min_sorted_data, upfront_sorted_data)

            setDataHook((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: res_data } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {
        if (selectedFilters.id !== min_UpfrontPriceTime.id) {
            setSpinning(() => true)

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTime, setSourceStatusHook: setAllRecordCountVsTime, axiosToken: axiosToken, isFirstReload: firstReload, selectedFiltersHook: selectedFilters }),
                        getChartData(selectedFilters, min_UpfrontPriceTime, setMin_UpfrontPriceTime, axiosToken)
                    ])

                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadMUF(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    return (
        <div className="page_section">
            <div className="side_shadow" style={{ minWidth: isExtended ? "257px" : "57px", overflow: "auto" }}></div>
            <div className="page">
                <div className="filter_container">
                    {desire_position.map((key, index) => {
                        if (key === null) {
                            return <DateRange key={index} selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} />
                        } else {
                            let option = filters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return selectedFilters ? <DateRange key={index} selectedFilterHook={selectedFilters} setSelectedFilterHook={setSelectedFilters} /> : <Dummy key={index} value={key} />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={index} data={option} handleFunc={handleMultiselect} />
                                } else {
                                    return <MultiSelect key={index} data={option} handleFunc={handleMultiselect} />
                                }
                            }
                        }
                    })}
                </div>
                <div className="visualize">
                    <Loading spinning={spinning} />
                    <div className="chart_container2">
                        <AvailabilityChart data={allRecordCountVsTime.data} firstRender={firstReloadMUF} />
                    </div>
                    <div className="chart_container">
                        <LineChart2 data={min_UpfrontPriceTime.data} firstRender={firstReloadMUF} />
                    </div>
                </div>
            </div>
        </div>
    )
}